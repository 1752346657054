import { Search } from '@vakantiesnl/types/src';

import { useGlobalFiltersStore } from '../stores';
import { useGetGeo, initZooverCompositeSearchReqParams } from './queries';
import { useFilterStore } from '../stores/filtersStore';

export function useGetCompositeSearchParams(): Search.ZooverCompositeSearchBody {
	const filters = useFilterStore((s) => s.filters);
	const geo = useGetGeo();
	const globalFilters = useGlobalFiltersStore((s) => s.filters);

	return initZooverCompositeSearchReqParams(filters, globalFilters, geo);
}
