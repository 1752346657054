import { useRouter } from 'next/router';

/**
 * Using the asPath field may lead to a mismatch between client and server if the page is rendered using server-side rendering or automatic static optimization.
 * Avoid using asPath until the isReady field is true.
 */
export const useIsActivePage = (href: string): boolean | undefined => {
	const { asPath, isReady } = useRouter();
	if (isReady) return asPath === href;
};
