import { useMemo } from 'react';

import { ParsedUrlQuery, parse } from 'querystring';
import type { UrlObject } from 'url';

import { sortObject } from '@vakantiesnl/services/src/util/sortObjectKeys';
import { Chain, Geo, TourOperator } from '@vakantiesnl/types';

import { buildSearchQueryParams, generatePathname, getSearchPathnameQuery } from './utils';

export type Meta = {
	countries?: Geo.GeoLocation[];
	regions?: Geo.GeoLocation[];
	cities?: Geo.GeoLocation[];
	campaign?: string;
	accoType?: string[];
	departure_date?: string[];
	post_offset?: string;
	pre_offset?: string;
	page?: string;
	pax?: string;
	flexible_departure_date?: string;
	sort?: string;
	durations?: string | number[];
	min_price?: string;
	max_price?: string;
	mealplans?: string[];
	awards?: string[];
	distances?: string[];
	facilities?: string[];
	themes?: string[];
	attributes?: string[];
	transport?: string;
	airports?: string[];
	rating?: string;
	deals?: string;
	chains?: Chain[];
	stars?: string[];
	tourOperators?: TourOperator[];
};

export type SearchUrl = {
	href: Omit<UrlObject, 'query'> & {
		query: ParsedUrlQuery;
	};
	as: UrlObject;
};
export type SearchUrlBuilderOptions = {
	pathname?: string;
	asQueryParams: URLSearchParams;
	hrefQuery: Record<string, string | undefined>;
};

export const searchUrlBuilderOptions = (meta: Meta, routerQuery?: ParsedUrlQuery): SearchUrlBuilderOptions => {
	/** Build query based on @searchRoutes, this is used to create the pathname */
	const pathNameQuery = getSearchPathnameQuery(meta);
	const pathname = generatePathname(pathNameQuery);

	/** Build params from meta object and routerQuery, excluding anything part of the pathname */
	const asQueryParams = buildSearchQueryParams(meta, pathNameQuery, routerQuery);

	/** Build hrefQuery, this contains all filters, so combine pathNameQuery with queryParams */
	const hrefQuery = { ...pathNameQuery, ...parse(asQueryParams.toString()) };

	return {
		pathname,
		hrefQuery,
		asQueryParams,
	};
};

/**
 * This builds the url for the search page, containing the href and as values
 * The HREF-variable is used to build the URL, the search-page containing all the necessary queries
 * The AS-variable is used to build the URL to show in the browser
 *
 * @param {Meta} meta - Meta data based on filters.
 */
export const useSearchUrlBuilder = (meta: Meta, routerQuery?: ParsedUrlQuery): SearchUrl =>
	useMemo(() => {
		const { pathname, asQueryParams: asQuery, hrefQuery } = searchUrlBuilderOptions(sortObject(meta), routerQuery);

		return {
			href: {
				pathname: '/search',
				query: hrefQuery,
			},
			as: {
				pathname: pathname ?? '/zoeken',
				query: asQuery.toString(),
			},
		};
	}, [meta, routerQuery]);
