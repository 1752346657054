export const UPDATE_AIRPORTS_TRIP_FILTER = 'UPDATE_AIRPORTS_TRIP_FILTER';
export const UPDATE_DEPARTURE_DATE_TRIP_FILTER = 'UPDATE_DEPARTURE_DATE_TRIP_FILTER';
export const UPDATE_DURATION_RANGE_TRIP_FILTER = 'UPDATE_DURATION_RANGE_TRIP_FILTER';
export const UPDATE_MEALPAN_TRIP_FILTER = 'UPDATE_MEALPAN_TRIP_FILTER';
export const UPDATE_INVALID_PRESELECTION = 'UPDATE_INVALID_PRESELECTION';
export const UPDATE_SELECTED_TRIP = 'UPDATE_SELECTED_TRIP';
export const UPDATE_SELECTED_DIRECT_ONLY = 'UPDATE_SELECTED_DIRECT_ONLY';
export const UPDATE_SELECTED_FLIGHT = 'UPDATE_SELECTED_FLIGHT';
export const UPDATE_SELECTED_ROOM_TYPE = 'UPDATE_SELECTED_ROOM_TYPE';
export const UPDATE_SELECTED_MEAL_PLAN = 'UPDATE_SELECTED_MEAL_PLAN';
export const UPDATE_IS_INITIAL_LOAD = 'UPDATE_IS_INITIAL_LOAD';
export const RESET_TRIP_FILTER = 'RESET_TRIP_FILTER';
