import { ParsedUrlQuery } from 'querystring';

import {
	countries as countriesManifest,
	themes as themesManifest,
	accoTypes as accoTypesManifest,
	attributeCampaigns as attributeCampaignsManifest,
	CampaignConfig,
} from '@vakantiesnl/services/src/route-params';

import { Meta } from './useSearchUrlBuilder';
import { FilterState, getCampaignDepartureDate } from '../../stores/filtersStore';
import { isNotEmpty } from '../../util/arrayUtils';
import { getDateYMD, parseToYMDDate } from '../../util/dateUtils';
import { getAllParamsExceptRouteParams, parseToValidParamValue } from '../../util/queryHelpers';

export const CAMPAIGN_GEO_ENTITY_ID_KEYS = ['countries', 'regions', 'cities'] as const;
export const CAMPAIGN_DEPARTURE_KEYS = ['departure_date', 'post_offset', 'pre_offset'] as const;

export function getCampaignConfig(filters: FilterState): CampaignConfig | null {
	if (!attributeCampaignsManifest || !filters.campaign) {
		return null;
	}

	const campaign = attributeCampaignsManifest[filters.campaign] || null;

	return campaign;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getCampaignMatch(filters: FilterState) {
	const campaign = getCampaignConfig(filters);

	/**
	 * Start with everything set on false. Then whenever a match is true, set that one to true.
	 * hasValidCampaignMatch will be set in the end and be determined based on only matches that are part of the campaign
	 */
	const matcher = {
		departureDate: false,
		accoType: false,
		countries: false,
		regions: false,
		cities: false,
		mealplans: false,
		attributes: false,
		minPrice: false,
		maxPrice: false,
		hasValidCampaignMatch: false,
	};

	if (!campaign) {
		return matcher;
	}

	// For each filter on the campaign, check if there is a match with the selected filters.
	// For some filters we check if the value is within a range, f.e. departureDate.
	const hasCampaignMatch: boolean[] = [];

	/** Mealplan */
	const hasMealplanMatch = !!campaign.mealplans?.length
		? campaign.mealplans.every((acco) => filters.mealplans.includes(acco)) &&
			campaign.mealplans.length === filters.mealplans.length
		: !filters.mealplans.length;

	if (!!campaign.mealplans?.length) {
		matcher.mealplans = hasMealplanMatch;
		hasCampaignMatch.push(hasMealplanMatch);
	}

	/** Attributes */
	const hasAttributeMatch = !!campaign.attributes?.length
		? campaign.attributes.every((acco) => filters.attributes.includes(acco)) &&
			campaign.attributes.length === filters.attributes.length
		: !filters.attributes.length;

	if (!!campaign.attributes?.length) {
		matcher.attributes = hasAttributeMatch;
		hasCampaignMatch.push(hasAttributeMatch);
	}

	/** Geo */
	const hasGeoMatch = CAMPAIGN_GEO_ENTITY_ID_KEYS.every((geoKey) => {
		const metaGeoValues = filters[geoKey] || [];
		const campaignEntityIdValues = campaign[geoKey] || [];

		return (
			campaignEntityIdValues.every((campaignEntityId) =>
				metaGeoValues.map((metaGeoValue) => metaGeoValue.entityId).includes(campaignEntityId),
			) && metaGeoValues.length === campaignEntityIdValues.length
		);
	});

	if (!!campaign.countries?.length) {
		hasCampaignMatch.push(hasGeoMatch);

		matcher.cities = hasGeoMatch;
		matcher.regions = hasGeoMatch;
		matcher.countries = hasGeoMatch;
	}

	/** AccoType */
	const campaignAccos = campaign.accoType?.split('+');
	const selectedAccos = filters.accoType.map((a) => accoTypesManifest[a]).filter(isNotEmpty);
	const hasAccoTypeMatch = campaignAccos
		? campaignAccos.every((acco) => selectedAccos.includes(acco)) && campaignAccos.length === selectedAccos.length
		: !selectedAccos.length;

	if (campaign.accoType) {
		matcher.accoType = hasAccoTypeMatch;
		hasCampaignMatch.push(hasAccoTypeMatch);
	}

	/** Min Price */
	const hasMinPriceMatch = filters.priceMin === (campaign.price_min ?? null);

	if (campaign.price_min) {
		matcher.minPrice = hasMinPriceMatch;

		const hasPriceInRange = filters.priceMin
			? parseInt(filters.priceMin) >= parseInt(campaign.price_min)
			: hasMinPriceMatch;
		hasCampaignMatch.push(hasPriceInRange);
	}

	/** Max Price */
	const hasMaxPriceMatch = filters.priceMax === (campaign.price_max ?? null);

	if (campaign.price_max) {
		matcher.maxPrice = hasMaxPriceMatch;

		const hasPriceInRange = filters.priceMax
			? parseInt(filters.priceMax) <= parseInt(campaign.price_max)
			: hasMaxPriceMatch;
		hasCampaignMatch.push(hasPriceInRange);
	}

	/** DepartureDate */
	const campaignDates = getCampaignDepartureDate(campaign) || [];
	const filterDates = filters.departureDate || [];
	const hasDepartureDateMatch = campaignDates?.join('') === filterDates?.join('');

	if (campaign.departure_date || campaign.post_offset) {
		matcher.departureDate = hasDepartureDateMatch;
		hasCampaignMatch.push(getHasDepartureDateRangeMatch(campaignDates, filterDates));
	}

	return {
		...matcher,
		hasValidCampaignMatch: hasCampaignMatch.every((v) => v === true),
	};
}

// Exported for testing purposes
// Check the selected date(s) fall within range of the campaign date(s)
export function getHasDepartureDateRangeMatch(
	campaignDepartureDates: string[],
	filterDepartureDates: string[],
): boolean {
	const campaignDates = campaignDepartureDates.map(parseToYMDDate);
	const filterDates = filterDepartureDates.map(parseToYMDDate);

	// If none are filled in, we consider it to be a match
	if (!campaignDates.length && !filterDates.length) {
		return true;
	}

	// If either is not filled in, then we know for sure there cannot be a match
	if (!campaignDates.length || !filterDates.length) {
		return false;
	}

	// Easy check if there is a full match
	if (campaignDates.map(getDateYMD).join('') === filterDates.map(getDateYMD).join('')) {
		return true;
	}

	// Check date(s) fall within range of the campaign date(s)
	if (!!campaignDates.length && !!filterDates.length) {
		const campaignFrom = campaignDates[0];
		const campaignTo = campaignDates[1] || campaignDates[0];

		const filterFrom = filterDates[0];
		const filterTo = filterDates[1] || filterDates[0];

		return (
			filterFrom.isSameOrAfter(campaignFrom) &&
			filterFrom.isSameOrBefore(campaignTo) &&
			filterTo.isSameOrAfter(campaignFrom) &&
			filterTo.isSameOrBefore(campaignTo)
		);
	}

	return false;
}

/** Maps one or multiple theme slug(s) to the slug of the config in case of a match */
export const buildSlugByManifest = (
	manifest: Record<string, string> | string[],
	metaValues?: string | string[],
): string[] | undefined => {
	if (metaValues) {
		if (Array.isArray(metaValues)) {
			if (Array.isArray(manifest)) {
				const filteredManifest = manifest.filter((manifestValue) => metaValues.includes(manifestValue));
				/** Only return when the filtered result is containing a mapped result */
				return filteredManifest.length > 0 ? filteredManifest : undefined;
			} else {
				const filteredManifest = metaValues
					.filter((metaValue) => manifest[metaValue])
					.map((filteredValue) => manifest[filteredValue]);
				/** Only return when the filtered result is containing a mapped result */
				return filteredManifest.length > 0 ? filteredManifest : undefined;
			}
		} else {
			if (Array.isArray(manifest)) {
				return manifest.includes(metaValues) ? [metaValues] : undefined;
			} else {
				const mappedSlug = manifest[metaValues];
				return mappedSlug ? [mappedSlug] : undefined;
			}
		}
	}
};

export function filterOutEmptyParams(queryParams: URLSearchParams): URLSearchParams {
	const filteredQueryParams = new URLSearchParams(queryParams);

	/**
	 * Clean up empty query params
	 * Built with two loops because the forEach on URLSearchParams is very inconsistent
	 */
	const keysToDelete: string[] = [];
	filteredQueryParams.forEach((value, key) => {
		if (value === '') {
			keysToDelete.push(key);
		}
	});

	keysToDelete.forEach((key) => {
		filteredQueryParams.delete(key);
	});

	return filteredQueryParams;
}

/**
 * Builds query params from the meta, excluding anything already part of the pathname
 * Connects any array values with a '+'
 */
export const buildSearchQueryParams = (
	meta: Meta,
	searchPathnameQuery: SearchPathnameQuery,
	routerQuery: ParsedUrlQuery = {},
): URLSearchParams => {
	const unknownRouterParams = getAllParamsExceptRouteParams(routerQuery);

	const allQueryParams = new URLSearchParams(unknownRouterParams);

	/**
	 * Loop over all keys to check
	 * Built with two loops because the forEach on URLSearchParams is very inconsistent
	 */
	(Object.keys(meta) as Array<keyof Meta>).forEach((key) => {
		switch (key) {
			case 'themes':
				if (searchPathnameQuery.themes) break;

				const themeMetaValue = meta[key];
				const mappedThemeToSlug = buildSlugByManifest(themesManifest, themeMetaValue);
				const themeValue = parseToValidParamValue(mappedThemeToSlug);

				allQueryParams.set(key, themeValue);
				break;
			case 'accoType':
				if (searchPathnameQuery.accoType) break;

				const accoTypeMetaValue = meta[key];
				const mappedAccoTypeToSlug = buildSlugByManifest(accoTypesManifest, accoTypeMetaValue);
				const accoTypeValue = parseToValidParamValue(mappedAccoTypeToSlug);

				allQueryParams.set(key, accoTypeValue);
				break;

			case 'chains':
				/**
				 * @if @chain it's a single chain url
				 * @else multiple chains are added and the query param "chains" is added
				 */
				if (searchPathnameQuery.chain) break;

				const chainsMetaValue = meta[key];

				chainsMetaValue && allQueryParams.set(key, chainsMetaValue.map((value) => value.entityId).join('+'));
				break;
			case 'tourOperators':
				/**
				 * @if @tourOperator it's a single chain url
				 * @else multiple tourOperators are added and the query param "tourOperators" is added
				 */
				if (searchPathnameQuery.tourOperator) break;

				const tourOperatorsMetaValue = meta[key];

				tourOperatorsMetaValue &&
					allQueryParams.set(key, tourOperatorsMetaValue.map((value) => value.tour_operator).join('+'));
				break;

			case 'countries':
			case 'regions':
			case 'cities':
				if (searchPathnameQuery.country || searchPathnameQuery.region || searchPathnameQuery.city) break;

				const geoMetaValue = meta[key];
				geoMetaValue && allQueryParams.set(key, geoMetaValue.map((value) => value.entityId).join('+'));
				break;

			case 'campaign':
				if (searchPathnameQuery.campaign) break;

				const campaign = parseToValidParamValue(meta[key]);
				allQueryParams.set(key, campaign);

				break;

			case 'deals':
				// Never add deals as a query param
				break;

			default:
				const metaValue = parseToValidParamValue(meta[key]);
				allQueryParams.set(key, metaValue);
		}
	});

	allQueryParams.sort();

	return filterOutEmptyParams(allQueryParams);
};

export const generatePathname = (query: Record<string, string>): string | undefined => {
	const path = Object.entries(query).map((entry) => entry[1]);

	if (!path.length) {
		return undefined;
	}

	return path.reduce((prev, path) => {
		if (path) {
			return `${prev ? prev : ''}/${path}`;
		}
		return prev;
	}, '');
};

/** NB: Keys should match variables from @searchRoutes */
export type SearchPathnameQuery = {
	country?: string;
	city?: string;
	region?: string;
	themes?: string;
	deals?: 'deals';
	campaign?: string;
	attributes?: string;
	accoType?: string;
	chain?: string;
	tourOperator?: string;
};

/** Returns the query to build a href equal to route definitions in @searchRoutes  */
export const getSearchPathnameQuery = (meta: Meta): SearchPathnameQuery => {
	const query: SearchPathnameQuery = {};

	if (meta.countries && meta.countries.length === 1) {
		const countrySlugFromManifest = buildSlugByManifest(
			countriesManifest,
			meta.countries.map((c) => c.slug),
		);
		if (
			meta.countries.length === 1 &&
			(!meta.regions || meta.regions.length < 2) &&
			(!meta.cities || meta.cities.length < 2)
		) {
			/** Only set the hrefQuery.countries when countries were found in the manifest */
			if (countrySlugFromManifest) {
				query.country = countrySlugFromManifest[0];
			}
		}

		/** Regions are only valid with 1 or more countrySlugs */
		if (query.country && meta.regions && meta.regions.length > 0) {
			if (meta.regions.length === 1 && meta.countries.length === 1 && (!meta.cities || meta.cities.length < 2)) {
				query.region = meta.regions[0].slug;
			}

			/** Cities are only valid with 1 or more countrySlugs */
			if (query.region && meta.cities && meta.cities.length > 0) {
				if (meta.cities.length === 1 && meta.regions.length === 1 && meta.countries.length === 1) {
					query.city = meta.cities[0].slug;
				}
			}
		}
	}

	if (meta.accoType && meta.accoType.length <= 1) {
		query.accoType = buildSlugByManifest(accoTypesManifest, meta.accoType)?.[0];
	}

	/** Only set theme in the pathname when no campaigns is set */
	if (meta.themes && meta.themes.length <= 1 && !meta.campaign) {
		query.themes = buildSlugByManifest(themesManifest, meta.themes)?.[0];
	}

	if (meta.deals === 'true') {
		query.deals = 'deals';
	}

	if (meta.campaign) {
		query.campaign = meta.campaign;
	}

	/** Chains only work on geo level and should be a clean path when only 1 chain is selected */
	if (
		meta.chains?.length === 1 &&
		!query.deals &&
		!query.accoType &&
		!query.themes &&
		!query.campaign &&
		!query.tourOperator
	) {
		query.chain = meta.chains[0].slug;
	}
	/** TourOperators only work on geo level and should be a clean path when only 1 chain is selected */
	if (
		meta.tourOperators?.length === 1 &&
		!query.deals &&
		!query.accoType &&
		!query.themes &&
		!query.campaign &&
		!query.chain
	) {
		query.tourOperator = meta.tourOperators[0].slug;
	}

	return query;
};
